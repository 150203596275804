import React from "react";
import whatsapp from "../assets/images/WhatsApp.svg.webp";
import "../otherFiles/css/whatsapp.css";

const Whatsapp = () => {
  return (
    <div>
      <div className="whatsapp-float">
        <a
          href="https://wa.me/message/VH5HQOAHFJFBK1"
          target="_blank"
          rel="noreferrer"
        >
          <img src={whatsapp} alt="WhatsApp" />
        </a>
      </div>
    </div>
  );
};

export default Whatsapp;
