import React from "react";
import { Link } from "react-router-dom";
import "../../otherFiles/css/footer.css";

const Footer = () => {
  return (
    <div>
      <footer className="content-footer footer bg-footer-theme">
        <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
          <div className="copyright mb-2 mb-md-0">
            {" "}
            {/* Added class "copyright" */}© {new Date().getFullYear()}{" "}
            Developed By{" "}
            <a
              className="footer-link fw-bolder"
              target="_blank"
              href="https://www.thinkhat.in/"
              rel="noreferrer"
            >
              Think Hat Business Solutions
            </a>
            <span className="pipe"> | </span>
            <span>Powered By Meta Llama 3</span>
          </div>
          <div className="links">
            {" "}
            {/* Added class "links" */}
            <Link className="footer-link me-4" to="/terms-and-conditions">
              Terms &amp; Conditions
            </Link>
            <Link className="footer-link me-4" to="/privacy-policy">
              Privacy Policy
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
