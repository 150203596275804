import React, { useEffect, useState } from "react";
import "../otherFiles/css/Signin.css";
import Logo from "../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { signinUser } from "../utils/auth.js";
import { toast } from "sonner";
import Footer from "../components/ChatPage/Footer.jsx";
import Loader from "../helper/Loader.jsx";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "../otherFiles/css/phoneInput.css";
import Whatsapp from "../helper/Whatsapp.jsx";

const Signin = () => {
  const [loader, setLoader] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is already logged in
    const verified =
      JSON.parse(localStorage.getItem("isVerified")) ||
      JSON.parse(sessionStorage.getItem("isVerified"));
    if (verified) {
      navigate("/");
    }
  }, [navigate]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSignin = async () => {
    if (!mobileNumber || !password) {
      toast.error("Please Fill In All Fields");
      return;
    }

    setLoader(true);
    try {
      const signinResponse = await signinUser(password, mobileNumber);

      if (signinResponse?.status === true) {
        const user_id = signinResponse?.data?.user_id;
        const auth_token = signinResponse?.data?.access_token;

        // A = user_id & B = auth_token
        const obj = { A: user_id, B: auth_token };
        if (user_id) {
          if (rememberMe) {
            localStorage.setItem("isVerified", JSON.stringify(obj));
            sessionStorage.setItem("isVerified", JSON.stringify(obj));
          } else {
            sessionStorage.setItem("isVerified", JSON.stringify(obj));
          }
          navigate("/");
          toast.success("Signed In Successfully");
        } else {
          navigate("/signin");
          toast.error("Sign In Failed");
        }
      } else {
        navigate("/signin");
        toast.error("Sign In Failed. Please Check Your Credentials");
      }
    } catch (error) {
      navigate("/signin");
      console.error("Error During Sign In:", error.message);
    } finally {
      setLoader(false);
    }
  };

  const handlePhone = (e) => {
    while (e?.charAt(0) === "+") {
      e = e.substring(1);
    }
    setMobileNumber(e);
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="container-xxl">
          <div className="authentication-wrapper authentication-basic container-p-y">
            <div className="authentication-inner">
              <div className="card">
                <div className="card-body">
                  <div className="app-brand justify-content-center">
                    <div className="app-brand-link gap-2">
                      <span>
                        <img
                          src={Logo}
                          style={{ width: "50px", height: "auto" }}
                          alt="Logo"
                        />
                      </span>
                      <span className="app-brand-text demo text-body fw-bolder">
                        Think Chat
                      </span>
                    </div>
                  </div>
                  <h4 className="mb-2 text-capitalize">
                    Welcome to Think Chat! 👋
                  </h4>
                  <p className="mb-4">
                    Securely sign in to access your personalized chat experience
                    on Think Chat!
                  </p>

                  <div className="mb-3">
                    <label htmlFor="mobile" className="form-label">
                      Mobile Number
                    </label>
                    <PhoneInput
                      defaultCountry="IN"
                      id="mobile"
                      onChange={(e) => handlePhone(e)}
                      inputProps={{
                        name: "mobile",
                        required: true,
                        autoFocus: true,
                      }}
                    />
                  </div>

                  <div className="mb-3 form-password-toggle">
                    <div className="d-flex justify-content-between">
                      <label className="form-label" htmlFor="password">
                        Password
                      </label>
                      <Link to="/forgot-password">
                        <small>Forgot Password?</small>
                      </Link>
                    </div>
                    <div className="input-group input-group-merge">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        className="form-control"
                        id="password"
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        aria-describedby="password"
                      />
                      <span
                        className="input-group-text cursor-pointer"
                        onClick={togglePasswordVisibility}
                      >
                        <i
                          className={`bx ${
                            passwordVisible ? "bx-show" : "bx-hide"
                          }`}
                        ></i>
                      </span>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="remember-me"
                        onChange={() => setRememberMe(!rememberMe)}
                      />
                      <label className="form-check-label" htmlFor="remember-me">
                        Remember Me
                      </label>
                    </div>
                  </div>

                  <div className="mb-3">
                    <button
                      className="btn btn-primary d-grid w-100"
                      type="button"
                      onClick={handleSignin}
                    >
                      Sign In
                    </button>
                  </div>

                  <p className="text-center">
                    <span>New on our platform?</span>
                    <Link to="/signup">
                      <span> Create An Account</span>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          <Whatsapp />
        </div>
      )}
    </>
  );
};

export default Signin;
