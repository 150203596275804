import React, { useEffect, useRef, useState } from "react";
import Logo from "../../assets/images/logo.png";
import Arrow from "../../assets/images/arrowDown.png";
import { useUserContext } from "../../context/userContext";
import "../../otherFiles/css/tagStyles.css";
import "../../otherFiles/css/scrollButton.css";
import "../../otherFiles/css/typing.css";
import CodeHighlighter from "../../helper/CodeHighlighter";
import typing from "../../assets/images/typing.gif";

const Messages = () => {
  const { chatData, responseData, setChatData, setResponseData } =
    useUserContext();
  const [showScrollButton, setShowScrollButton] = useState(false);

  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  // Safely retrieve chat history from sessionStorage or fallback to data
  const storedData = sessionStorage.getItem("message_history")
    ? JSON.parse(sessionStorage.getItem("message_history"))
    : [];

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (chatData.length > 0) {
      scrollToBottom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatData]);

  useEffect(() => {
    // Clear chatData and responseData after response has been displayed
    if (chatData.length > 0 && responseData.length > 0) {
      setChatData([]);
      setResponseData([]);
    }
  }, [chatData.length, responseData.length, setChatData, setResponseData]);

  const containsPreCodeTags = (content) => {
    return content.includes("<pre><code>") && content.includes("</code></pre>");
  };

  const processChatContent = (content) => {
    if (!containsPreCodeTags(content)) {
      return <div dangerouslySetInnerHTML={{ __html: content }} />;
    }

    // Split content on <pre><code> and </code></pre> while preserving the code blocks
    const parts = content.split(/(<pre><code>.*?<\/code><\/pre>)/gs);

    return parts.map((part, index) => {
      if (part.startsWith("<pre><code>") && part.endsWith("</code></pre>")) {
        // Extract code content from <pre><code> block
        const codeContent = part
          .replace("<pre><code>", "")
          .replace("</code></pre>", "")
          .replace(/<br\s*\/?>/gi, "\n"); // Replace <br> tags with newline characters

        return <CodeHighlighter key={index} response={codeContent} />;
      } else {
        return <div key={index} dangerouslySetInnerHTML={{ __html: part }} />;
      }
    });
  };

  // Check if user is not at the bottom, then show the scroll button
  const handleScroll = () => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      const scrollTop = chatContainer.scrollTop;
      const clientHeight = chatContainer.clientHeight;
      const scrollHeight = chatContainer.scrollHeight;

      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 1; // Added -1 for accuracy

      setShowScrollButton(!isAtBottom);
    }
  };

  // Attach scroll event listener
  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
      return () => {
        chatContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-lg-12 mb-4 order-0">
          <div className="card sec-chat-box">
            <div
              className="d-flex align-items-end row"
              ref={chatContainerRef}
              style={{
                // height: "65vh",
                overflowY: "auto",
                overflowX: "hidden",
                padding: 0,
                margin: 0,
              }}
            >
              <div className="col-sm-12">
                {/* Check if there is chat history available */}
                {storedData &&
                  storedData?.map((chat, index) => (
                    <div key={index} className="card-body">
                      {chat?.role === "user" && (
                        <div className="w-full chat-user">
                          <div className="chat-bubble-1">
                            <p style={{ whiteSpace: "pre-wrap" }}>
                              {chat?.content}
                            </p>
                          </div>
                        </div>
                      )}
                      {(chat?.role === "assistant" ||
                        chat?.role === "system") && (
                        <div className="w-full chat-reply">
                          <div className="chat-icon">
                            <img src={Logo} alt="Bot Logo" />
                          </div>
                          <div className="chat-bubble-0">
                            <div style={{ whiteSpace: "pre-wrap" }}>
                              {processChatContent(chat?.content)}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                <div className="card-body">
                  {chatData?.map((chat, index) => (
                    <div key={index}>
                      {chat.userMessage && (
                        <div className="w-full chat-user">
                          <div className="chat-bubble-1">
                            <p style={{ whiteSpace: "pre-wrap" }}>
                              {chat.userMessage}
                            </p>
                          </div>
                        </div>
                      )}
                      {responseData?.[index] ? (
                        <div className="w-full chat-reply">
                          <div className="chat-icon">
                            <img src={Logo} alt="Bot Logo" />
                          </div>
                          <div className="chat-bubble-0">
                            <div style={{ whiteSpace: "pre-wrap" }}>
                              {processChatContent(
                                responseData[index]?.botResponse
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="w-full chat-reply">
                          <div className="chat-icon">
                            <img src={Logo} alt="Bot Logo" />
                          </div>
                          <div className="chat-bubble-0">
                            <div className="typing-indicator">
                              <img src={typing} alt="Fetching..." />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div ref={messagesEndRef} />
            </div>
          </div>
        </div>
      </div>
      {showScrollButton && (
        <button className="floating-button" onClick={scrollToBottom}>
          <img src={Arrow} alt="" />
        </button>
      )}
    </div>
  );
};

export default Messages;
