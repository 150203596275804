import React, { createContext, useContext, useState } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [responseData, setResponseData] = useState([]);
  const [chatData, setChatData] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);

  const addChat = (userMessage, botResponse) => {
    setChatHistory(prevHistory => [
      ...prevHistory,
      { userMessage, botResponse }
    ]);
  };

  return (
    <UserContext.Provider
      value={{
        responseData,
        setResponseData,
        chatData,
        setChatData,
        chatHistory, // Expose chat history
        addChat,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
